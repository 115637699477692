import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import { Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import _ from "lodash"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import {
  getBrokerPair,
  getColor,
  getPairStrip,
  getWeekStart,
  typedKeys,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { breakpointNum } from "../../styles"
import { GraphInfo, GraphReactComponent, VolumePairTick } from "../../types"
import { AuthContext } from "../auth-context"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import VolumePairMemoizedGraph from "../volume-pair-memoized-graph"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatVolume } from "./helpers"
import * as S from "./styled"

const pointStickOptions = [
  {
    value: 1,
    label: "1 MINUTE",
  },
  {
    value: 5,
    label: "5 MINUTES",
  },
  {
    value: 15,
    label: "15 MINUTES",
  },
  {
    value: 60,
    label: "1 HOUR",
  },
]

const volumePairIndexedDBValidator = z.object({
  data: z
    .object({
      pair: z.string(),
      data: z.array(z.tuple([z.number(), z.number()])),
    })
    .array(),
  savedAt: z.number(),
})

const VolumePair: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
}) => {
  const router = useNavigate()
  const { getCurrentUser } = useContext(AuthContext)
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const rightContentWrapper = useRef<HTMLDivElement>(null)
  const [displayOptions, setDisplayOptions] = useState<
    {
      sortlabel: string
      value: string
      label: string
    }[]
  >([{ sortlabel: "00_ALL", value: "pairs", label: "ALL PAIRS" }])
  const [display, setDisplay] =
    useState<(typeof displayOptions)[number]["value"]>("pairs")
  const brokerPair = useMemo(() => getBrokerPair("aggregated", "all"), [])
  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "volumePairs",
      id,
      Graph: VolumePair,
    }),
    [broker, id, pair],
  )
  const [volumePairs, setVolumePairs] = useState<Record<string, number>>({})
  const [chunkSizeMinutes, setChunkSizeMinutes] = useState<number>(1)
  const [historicalData, setHistoricalData] = useState<
    {
      pair: string
      data: [number, number][]
    }[]
  >()
  const { colorsMapper } = useContext(PairsBrokersListContext)

  // here we do not listen to the instantaneous data, but we only keep the
  // info about the cumulated values!!
  const volumePairListener = useCallback(
    (tick: VolumePairTick) => {
      if (tick.brokerPair !== brokerPair) return

      setVolumePairs({
        ...volumePairs,
        ...tick.data.cumulated, // <-- that's the only info of the tick we use
      })
    },
    [brokerPair, volumePairs],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("volumePairs", volumePairListener)

    return () => socket.off("volumePairs", volumePairListener)
  }, [socket, graphs, volumePairListener])

  //Draw points
  useEffect(() => {
    if (typedKeys(colorsMapper).length === 0) {
      console.warn("MISSING COLORS. Please Save a palette in the /settings")
      return
    }

    if (!historicalData) return
    if (!chartComponentRef.current?.chart) return

    const series: Series[] | undefined =
      chartComponentRef.current?.chart?.series

    // VOLUMES HANDLING AND DRAW POINTS
    historicalData.forEach(serieData => {
      const dataToPlot = _.chunk(serieData.data, chunkSizeMinutes)
        .filter(chunk => chunk.length === chunkSizeMinutes)
        .map(chunk => [
          chunk[Math.floor(chunkSizeMinutes / 2)][0],
          _.sum(chunk.map(p => p[1])),
        ])
      const pairStrip = getPairStrip(serieData.pair)
      const serie: Series | undefined = series?.find(x => x.name === pairStrip)

      if (!serie) {
        chartComponentRef.current?.chart?.addSeries(
          {
            data: dataToPlot,
            type: "line",
            name: pairStrip,
            color: getColor(
              colorsMapper,
              serieData.pair.replace("aggregated-", ""),
            ),
            showInNavigator: true,
            boostThreshold: 1,
          },
          false,
          false,
        )
      } else serie?.setData(dataToPlot, false, false)
    })

    //update visible series
    series?.forEach(serie => {
      if (serie.name.includes("Navigator")) return
      serie.setVisible(
        serie.name === display || (display === "pairs" && serie.name !== "SUM"),
      )
    })

    // REDRAW THE CHART
    chartComponentRef.current?.chart.redraw(false)
  }, [chunkSizeMinutes, historicalData, display, colorsMapper])

  // GET THE DATA
  useEffect(() => {
    const asyncTrick = async () => {
      const user = await getCurrentUser()
      if (!user.isLogged) {
        router("/login")
        return
      }

      const indexDbKey = `volumePair-${brokerPair}`

      const loadedSafeData = volumePairIndexedDBValidator.safeParse(
        await indexDb.get(indexDbKey),
      )

      let cachedData: z.infer<typeof volumePairIndexedDBValidator> = {
        data: [],
        savedAt: 0,
      }

      if (loadedSafeData.success) {
        cachedData = loadedSafeData.data
      }

      if (cachedData.savedAt < getWeekStart().getTime()) {
        cachedData = { data: [], savedAt: 0 }
        indexDb.remove(indexDbKey)
      }

      try {
        const { data: res } = await axios.get<
          z.infer<typeof volumePairIndexedDBValidator>
        >(
          `${
            process.env.REACT_APP_ENDPOINT || ""
          }/liquidity-dashboard/pairs-volume`,
          {
            headers: {
              Authorization: user.tokens.token,
            },
            params: {
              from: cachedData.savedAt,
            },
          },
        )

        const pairsList = [
          ...new Set([
            ...cachedData.data.map(({ pair }) => pair),
            ...res.data.map(({ pair }) => pair),
          ]),
        ]
        const historicalData = pairsList.map(pair => {
          const oldCachedData =
            cachedData.data.find(x => x.pair === pair)?.data ?? []
          const newResData = res.data.find(x => x.pair === pair)?.data ?? []
          return {
            pair,
            data: [...oldCachedData, ...newResData],
          }
        })

        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        setDisplayOptions(oldOptions =>
          [
            ...oldOptions,
            ...historicalData.flatMap(({ pair }) => {
              const pairStrip = getPairStrip(pair)
              const pairInCombo = oldOptions.find(x => x.value === pairStrip)
              if (!pairInCombo) {
                return [
                  {
                    sortlabel: pairStrip === "SUM" ? "00_SUM" : pair,
                    value: pairStrip,
                    label: pairStrip.toUpperCase(),
                  },
                ]
              } else return []
            }),
          ].sort((a, b) => {
            const pair1 = a.sortlabel
            const pair2 = b.sortlabel
            if (pair1 < pair2) return -1
            if (pair1 > pair2) return 1
            return 0
          }),
        )

        setHistoricalData(historicalData)
      } catch (err) {
        console.error(err)
      }
    }

    asyncTrick()
    const interval = setInterval(asyncTrick, 60_000)
    return () => clearInterval(interval)
  }, [broker, getCurrentUser, router, brokerPair, display])

  useEffect(() => {
    if (!chartComponentRef) return
    chartComponentRef.current?.chart.series.forEach(serie => {
      if (serie.name.includes("Navigator")) return
      serie.setVisible(
        serie.name === display || (display === "pairs" && serie.name !== "SUM"),
      )
    })
    chartComponentRef.current?.chart.redraw(false)
  }, [display])

  useResizeObserver(rightContentWrapper, () => {
    chartComponentRef?.current?.chart?.yAxis?.[0]?.update(
      {
        visible: window.innerWidth > breakpointNum.mobile,
      },
      true,
    )
    return window.dispatchEvent(new Event("resize"))
  })

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Pairs Volume (USD)"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "select",
            optionsValues: displayOptions,
            onChange: value => {
              setDisplay(value)
            },
            value:
              displayOptions.find(({ value }) => display === value)?.label ??
              "",
          },
          {
            type: "select",
            optionsValues: pointStickOptions,
            onChange: value => {
              setChunkSizeMinutes(value)
            },
            value:
              pointStickOptions.find(({ value }) => chunkSizeMinutes === value)
                ?.label ?? "",
          },
        ]}
      />
      <S.ContentWrapper>
        <S.LeftContentWrapper>
          <S.Grid>
            {typedKeys(volumePairs)
              .filter(pair => !pair.includes("-all"))
              .sort((p1, p2) => {
                const pair1 = p1
                const pair2 = p2
                if (pair1 < pair2) return -1
                if (pair1 > pair2) return 1
                return 0
              })
              .map(pair => (
                <S.Row key={pair}>
                  <S.PairDotWrapper>
                    <S.Dot
                      color={getColor(
                        colorsMapper,
                        pair.replace("aggregated-", ""),
                      )}
                    />
                    <S.Label>{getPairStrip(pair)}</S.Label>
                  </S.PairDotWrapper>
                  <S.Value>{formatVolume(volumePairs[pair])}</S.Value>
                </S.Row>
              ))}
          </S.Grid>
          <S.ConsolidatedVolumeRow>
            <S.Label>SUM</S.Label>
            <S.Value>
              {formatVolume(volumePairs["aggregated-all"] ?? 0)}
            </S.Value>
          </S.ConsolidatedVolumeRow>
        </S.LeftContentWrapper>
        <S.RightContentWrapper
          className="immovable"
          ref={rightContentWrapper}
          onMouseDown={e => e.stopPropagation()}
        >
          <VolumePairMemoizedGraph
            chartComponentRef={chartComponentRef}
            chunkSizeMinutes={chunkSizeMinutes}
          />
        </S.RightContentWrapper>
      </S.ContentWrapper>
    </WidgetWrapper>
  )
}

export default VolumePair
