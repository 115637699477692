import { useContext, useEffect } from "react"
import { AuthContext } from "../../components/auth-context"
import ColorsCard from "../../components/settings-card-colors"
import CommissionsCard from "../../components/settings-card-commissions"
import EnableArbitrageCard from "../../components/settings-card-enable-arbitrage"
import EnableBrokersCard from "../../components/settings-card-enable-brokers"
import ManualExposureCard from "../../components/settings-card-manual-exposure"
import MarginsCard from "../../components/settings-card-margins"
import PenalizationsCard from "../../components/settings-card-penalizations"
import ResetCard from "../../components/settings-card-reset"
import SlippageCard from "../../components/settings-card-slippage"
import StartingBalanceCard from "../../components/settings-card-starting-balance"
import * as S from "./styled"

export const Settings = () => {
  const { getCurrentUser } = useContext(AuthContext)

  useEffect(() => {
    getCurrentUser().then(user => {
      if (!user.isLogged) return
    })
  }, [getCurrentUser])

  if (process.env.REACT_APP_INSTANCE_TYPE === "crypto")
    return (
      <S.CardsWrapper>
        <StartingBalanceCard />
        <MarginsCard />
        <ColorsCard />
        <EnableBrokersCard />
        <CommissionsCard />
        <EnableArbitrageCard />
      </S.CardsWrapper>
    )

  return (
    <S.CardsWrapper>
      <StartingBalanceCard />
      <MarginsCard />
      <ColorsCard />
      <PenalizationsCard />
      <ResetCard />
      <ManualExposureCard />
      <SlippageCard />
    </S.CardsWrapper>
  )
}
